import React from 'react';
import { Datagrid, DateField, EmailField, List, TextField, ReferenceField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';

export const UserList = props => (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <EmailField source="email" />
        <ReferenceField source="account_id" reference="accounts"><TextField source="name"/></ReferenceField>
        <DateField source="created_at" />
        <TextField source="deleted_at" />
      </Datagrid>
    </List>
);

export const UserEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <ReferenceInput source="account_id" reference="accounts"><SelectInput optionText="name" /></ReferenceInput>
        <BooleanInput source="admin"/>
      </SimpleForm>
    </Edit>
);
