import {AUTH_LOGIN, AUTH_ERROR, AUTH_LOGOUT, AUTH_CHECK} from "react-admin";
import {fetchUtils} from "ra-core";

import jwtDecode from "jwt-decode";

const authProvider = (type, params) => {
    if (type === AUTH_LOGIN) {
        const {username, password} = params;
        const request = fetchUtils.fetchJson(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/login`, {
            method: "POST",
            body: JSON.stringify({email: username, password: password}),
            headers: new Headers({ 'Content-Type': 'application/json' })
        }).then(res => {
            if (res.status < 200 || res.status >= 300) {
                throw new Error(res.status.toString());
            }

            localStorage.setItem('token', res.json.token);
            return Promise.resolve();
        })
        return request;
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        let token;
        try {
            token = jwtDecode(localStorage.getItem('token'));
        } catch ( e ) {
            return Promise.reject(e);
        }

        console.log(token);

        if(!token) {
            return Promise.reject();
        }

        if(token.exp < (new Date() / 1000 | 0)) {
            return Promise.reject();
        }

        if(!token.admin) {
            return Promise.reject();
        }

        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    return Promise.resolve();
};


export default authProvider;
