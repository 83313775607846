import React from 'react';

import {
  BooleanInput, Datagrid, DateField,
  Edit, EmailField, List,
  NumberInput, ReferenceField,NumberField, BooleanField,
  SimpleForm, TextField,
  TextInput, Create
} from 'react-admin';
import { PriceField } from '../components/fields/PriceField';


export const SubscriptionPlanList = props => (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <NumberField source="users_per_account" />
        <NumberField source="projects_per_user" />
        <PriceField source="price_month"/>
        <PriceField source="price_year"/>
        <BooleanField source="public_plan" />
        <BooleanField source="default" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
);

export const SubscriptionPlanEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
        <NumberInput source="users_per_account" inputProps={{min: 1}}/>
        <NumberInput source="projects_per_user" inputProps={{min: 0}}/>
        <NumberInput source="price_month" inputProps={{min: 0}} format={v => (v/100).toFixed(2)} parse={v => v*100}/>
        <NumberInput source="price_year" inputProps={{min: 0}} format={v => (v/100).toFixed(2)} parse={v => Math.floor(v*100)}/>
        <BooleanInput source="can_remove_draft" />
        <BooleanInput source="can_edit_draft" />
        <BooleanInput source="public_plan" />
        <BooleanInput source="default" />
      </SimpleForm>
    </Edit>
);

export const SubscriptionPlanCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
        <NumberInput source="users_per_account" inputProps={{min: 1}}/>
        <NumberInput source="projects_per_user" inputProps={{min: 1}}/>
        <NumberInput source="price_month" inputProps={{min: 0}} format={v => (v/100).toFixed(2)} parse={v => v*100}/>
        <NumberInput source="price_year" inputProps={{min: 0}} format={v => (v/100).toFixed(2)} parse={v => Math.abs(v*100)}/>
        <BooleanInput source="public_plan" />
        <BooleanInput source="default" />
      </SimpleForm>
    </Create>
);
