import React from "react";

import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    TextField,
    BooleanField,
    Edit,
    SimpleForm,
    TextInput, SelectInput, ReferenceInput, BooleanInput, DateInput,
    TopToolbar,
    Button,
    useDataProvider
} from "react-admin";


export const AccountPaymentList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="account_id" reference="accounts"><TextField source="name"/></ReferenceField>
            <TextField source="sale_id"/>
            <TextField source="sale_status"/>
            <BooleanField source="continue"/>
            <DateField source="subscription_start"/>
            <DateField source="subscription_end"/>
        </Datagrid>
    </List>
);


const AccountPaymentEditActions = (props) => {

    const dataProvider = useDataProvider();

    console.log(props, dataProvider)

    if (!props.data) {
        return null;
    }

    const createInvoice = () => {
        dataProvider.create('invoices', {data: {account_payment_id: props.data.id}});
    }

    return (
        <TopToolbar>
            <Button color={"primary"} onClick={createInvoice}><>Create Invoice</>
            </Button>
        </TopToolbar>
    )
}

export const AccountPaymentEdit = props => (
    <Edit {...props} actions={<AccountPaymentEditActions/>}>
        <SimpleForm>
            <TextInput source="sale_id"/>
            <TextInput source="sale_status"/>

            <ReferenceField source="account_id" reference="accounts">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="account_id" reference="accounts">
                <TextField source="nip"/>
            </ReferenceField>
            <ReferenceField source="account_id" reference="accounts">
                <TextField source="address"/>
            </ReferenceField>
            <ReferenceField source="account_id" reference="accounts">
                <TextField source="postcode"/>
            </ReferenceField>
            <ReferenceField source="account_id" reference="accounts">
                <TextField source="city"/>
            </ReferenceField>
            <ReferenceField source="account_id" reference="accounts">
                <TextField source="country"/>
            </ReferenceField>

            <ReferenceInput source="account_id" reference="accounts"><SelectInput optionText="name"/></ReferenceInput>
            <BooleanInput source="continue"/>
            <DateInput source="subscription_start"/>
            <DateInput source="subscription_end"/>
        </SimpleForm>
    </Edit>
);
