import React from 'react';
import { Datagrid, DateField, List, TextField, Edit, SimpleForm, TextInput } from 'react-admin';

export const AccountList = props => (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="nip" />
        <TextField source="address" />
        <TextField source="postcode" />
        <TextField source="city" />
        <TextField source="country" />
        <DateField source="created_at" />
        <TextField source="deleted_at" />
      </Datagrid>
    </List>
);

export const AccountEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="nip" />
        <TextInput source="address" />
        <TextInput source="postcode" />
        <TextInput source="city" />
        <TextInput source="country" />
      </SimpleForm>
    </Edit>
);
