import React from "react";

import {
    Datagrid,
    NumberField,
    DateField,
    List,
    Edit,
    SimpleForm,
    TopToolbar,
    Button,
    useDataProvider,
    DateInput, NumberInput, SelectInput, ReferenceInput, TextInput
} from "react-admin";
import {PriceField} from "../components/fields/PriceField";

export const InvoiceList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <NumberField source={'id'}/>
            <DateField source={'date'}/>
            <NumberField source={'tax_rate'}/>
            <PriceField source="price_net"/>
        </Datagrid>
    </List>
);


const InvoiceEditActions = (props) => {

    if(!props.data) {
        return null;
    }

    return (
        <TopToolbar>
            <Button color={"primary"} href={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/invoices/${props.data.id}/download?token=${localStorage.getItem('token')}`}><>Download Invoice</></Button>
        </TopToolbar>
    )
}

export const InvoiceEdit = props => (
    <Edit {...props} actions={<InvoiceEditActions/>}>
        <SimpleForm>
            <NumberField source={'date'}/>
            <NumberInput source="price_net" inputProps={{min: 0}} format={v => (v/100).toFixed(2)} parse={v => Math.floor(v*100)} />
            <NumberInput source="tax_rate" inputProps={{min: 0}} />
            <ReferenceInput source="account_id" reference="accounts"><SelectInput optionText="name"/></ReferenceInput>
            <DateInput source={'date'}/>
            <TextInput multiline source="notes" />
        </SimpleForm>
    </Edit>
);
