import {
  Datagrid, DateField,
  Edit, EmailField,
  ImageField,
  ImageInput, List,
  SimpleForm, TextField,
  TextInput,
} from 'react-admin';
import React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { useFormState } from 'react-final-form';

const TypedInput = (props) => {
  const form = useFormState();

  switch ( form.values.type ) {
    case 'wysiwyg':
      return <RichTextInput { ...props }/>;
    default:
      return <TextInput { ...props }/>;

  }
};

export const SettingEdit = props => {
  return (
      <Edit { ...props }>
        <SimpleForm>
          <TextField source="name"/>
          <TypedInput source={"value"}/>
        </SimpleForm>
      </Edit>
  );
};

export const SettingList = props => (
    <List { ...props }>
      <Datagrid rowClick="edit">
        <TextField source="label"/>
        <DateField source="created_at"/>
      </Datagrid>
    </List>
);
