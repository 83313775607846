import React from 'react';
import { Datagrid, DateField, EmailField, List, TextField, Edit, SimpleForm, TextInput } from 'react-admin';

export const JobSearchList = props => (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="phone" />
        <DateField source="created_at" />
        <TextField source="deleted_at" />
      </Datagrid>
    </List>
);

export const JobSearchEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput type={"email"} source="email" />
        <TextInput source="phone" />
        <TextInput source="cv" />
        <TextInput source="lm" />
      </SimpleForm>
    </Edit>
);
