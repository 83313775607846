import axios from "axios";

class DataProvider {

  constructor(url) {
    this.url = url;

    this.getHeader = this.getHeader.bind(this);
    this.getList = this.getList.bind(this);
    this.getOne = this.getOne.bind(this);
    this.getMany = this.getMany.bind(this);
    this.getManyReference = this.getManyReference.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.updateMany = this.updateMany.bind(this);
    this.delete = this.delete.bind(this);
  }

  getHeader() {
    return {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }

  getUrl() {
    return this.url;
  }

  getList(resource, {pagination, sort, filter}) {
    const params = {};

    if(pagination) {
      params.page = pagination.page;
      params.perPage = pagination.perPage;
    }
    if(sort) {
      params.order = sort.field;
      params.orderDirection = sort.order === 'ASC' ? 0 : 1;
    }


    return axios.get(`${this.url}/${resource}`, {params, headers: this.getHeader()} ).then(res => {
      return {
        data: res.data.data.map(data => findImage(data)),
        total: res.data.meta.total,
      }
    });
  }
  getOne(resource, {id}) {
    return axios.get(`${this.url}/${resource}/${id}`, {headers: this.getHeader()}).then(res =>
      ({
        ...res.data,
        data: findImage(res.data.data)
      })
    );
  }
  getMany(resource, {ids}) {
    return Promise.all(ids.map(id => {
      return axios.get(`${this.url}/${resource}/${id}`, {headers: this.getHeader()}).then(res => ({
        ...res.data,
        data: findImage(res.data),
      }));
    })).then(responses => {
      return {data: responses.map(res => res.data)}
    });
  }
  getManyReference(resource) {
    console.log(resource);
    return Promise.reject();
  }
  async create(resource, {data}) {
    const parsedData = await parseData(data);
    return axios.post(`${this.url}/${resource}`, parsedData, {headers: this.getHeader()}).then(res => res.data);
  }
  async update(resource, {id, data}) {

    const parsedData = await parseData(data);

    return axios.put(`${this.url}/${resource}/${id}`, parsedData, {headers: this.getHeader()}).then(res => res.data);
  }
  updateMany(resource, {ids, data}) {
    return Promise.all(ids.map(id => this.update(resource, {id, data}))).then(console.log);
  }
  delete(resource, {id}) {
    return axios.delete(`${this.url}/${resource}/${id}`, {headers: this.getHeader()});
  }
  deleteMany(resource, {ids}) {
    return Promise.all(ids.map(id => this.delete(resource, {id})));
  }
}

const parseData = async (data) => {
  const parsedData = {...data};

  for (const [name, value] of Object.entries(data)) {
    if ( value && value.rawFile ) {
      parsedData[name] = await convertFileToBase64(value);
    }
    else {
      parsedData[name] = value;
    }
  }

  return parsedData;
}

const findImage = (data) => {
  const parsedData = {};

  for (const [name, value] of Object.entries(data)) {
    if ( value && value.src ) {
      if ( value.src.includes("http")) {
        parsedData[name] = value.src;
      } else {
        parsedData[name] = `${process.env.REACT_APP_URL}/storage/${value.src}`;
      }
    }
    else {
      parsedData[name] = value;
    }
  }

  return parsedData;
}

const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.rawFile);

  reader.onload = () => resolve({
    data: reader.result,
    type: file.rawFile.type,
    size: file.rawFile.size,
    name: file.rawFile.name,
  });
  reader.onerror = reject;
});

export default DataProvider;
