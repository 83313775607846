import { Datagrid, DateField, EmailField, List, TextField, Edit, SimpleForm, TextInput, ImageInput, ImageField } from 'react-admin';
import React from 'react';

export const AgencyEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="attendant" />
        <TextInput source="city" />
        <TextInput source="email" type={"email"} />
        <TextInput source="phone" />
        <TextInput source="site" />
        <TextInput source="description" multiline />
        <ImageField source="logo"/>
        <ImageInput source="new_logo" multiple={false} label={"Upload new logo"}>
          <ImageField source="src"/>
        </ImageInput>
      </SimpleForm>
    </Edit>
);

export const AgencyList = props => (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="attendant" />
        <TextField source="city" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextField source="site" />
        {/*<TextField source="logo" />*/}
        <DateField source="created_at" />
      </Datagrid>
    </List>
);
