import React from 'react';
import logo from './logo.svg';
import './App.css';

import background from "./background.jpg";

import { Admin, Resource, ListGuesser, EditGuesser, Login } from 'react-admin';
import DataProvider from './service/dataProvider';
import authProvider from "./service/authProvider";
import { AgencyEdit, AgencyList } from './resources/AgencyResource';
import { UserEdit, UserList } from './resources/UserResource';
import { AccountEdit, AccountList } from './resources/AccountResource';
import { JobSearchEdit, JobSearchList } from './resources/JobSearchResource';
import {
  SubscriptionPlanEdit,
  SubscriptionPlanList,
  SubscriptionPlanCreate
} from './resources/SubscriptionPlanResource';
import { SettingEdit, SettingList } from './resources/SettingResource';
import {AccountPaymentEdit, AccountPaymentList} from "./resources/AccountPaymentsResource";
import {InvoiceEdit, InvoiceList} from "./resources/InvoiceResource";

const dataProvider = new DataProvider(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`);

const OtoLogin = () => <Login backgroundImage={background} />;

function App() {
  return (
      <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={OtoLogin}>
        <Resource name="agencies" list={AgencyList} edit={AgencyEdit} />
        <Resource name="users" list={UserList} edit={UserEdit} />
        <Resource name="accounts" list={AccountList} edit={AccountEdit} />
        <Resource name="joboffers" list={ListGuesser} edit={EditGuesser} />
        <Resource name="subscriptionPlans" list={SubscriptionPlanList} edit={SubscriptionPlanEdit} create={SubscriptionPlanCreate}/>
        <Resource name="jobsearch" list={JobSearchList} edit={JobSearchEdit} />
        <Resource name="settings" list={SettingList} edit={SettingEdit} />
        <Resource name="accountPayments" list={AccountPaymentList} edit={AccountPaymentEdit} />
        <Resource name="invoices" list={InvoiceList} edit={InvoiceEdit} />
      </Admin>
  );
}

export default App;
